import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFolder } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <>
      <div className="bottom-container">
        <div className="botLeft">
          <div className="comImfo">
            <div className="fourDiv">
              <img alt="" src="/wordImg/image.png" />
            </div>
            <div className="footA">
              <Link to="/aboutUs">Get help</Link>
              <Link to="/privacy">Privacy 🚀</Link>
              <Link to="/contact">Contact Us</Link>
            </div>
          </div>
          <div className="comImfo1">
            <ul>
              <h3 className="comImfoH3">Company Information</h3>
              <li>
                <Link to="/contact">
                  <FontAwesomeIcon icon={faEnvelope} />
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/privacy">
                  <FontAwesomeIcon icon={faFolder} />
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/aboutUs">
                  <FontAwesomeIcon icon={faFolder} />
                  Support
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="legal">
          <div className="footLeftBot">
            <div className="legalLeft">
              © 2023 Ready Made Websites by sm web designs studio | WordPress +
              Divi Premium DIY Prebuilt website templates | all rights reserved.
              <Link to="/privacy">Privacy Policy</Link> | Terms | Sitemap.
            </div>
          </div>
          <div className="revisit">
            <img alt="" src="/wordpress/revisite.svg" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
