import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function Privacy() {
  return (
    <div className="main-container">
      <Header />

      <div className="PrivacyDiv">
        <h2>Ready Made Website Privacy Policy</h2>
        <p>
          This privacy policy is for this website; https://readymade-websites.co
          and served by sm web designs studio, Beech Lane, Wilmslow, SK9 5ER and
          governs the privacy of its users who choose to use it. It explains how
          we comply with the GDPR (General Data Protection Regulation), the DPA
          (Data Protection Act) [pre GDPR enforcement] and the PECR (Privacy and
          Electronic Communications Regulations).
        </p>
        <p>
          This policy will explain areas of this website that may affect your
          privacy and personal details, how we process, collect, manage and
          store those details and how your rights under the GDPR, DPA & PECR are
          adhere to. Additionally it will explain the use of cookies or
          software, advertising or commercial sponsorship from third parties and
          the download of any documents, files or software made available to you
          (if any) on this website. Further explanations may be provided for
          specific pages or features of this website in order to help you
          understand how we, this website and its third parties (if any)
          interact with you and your computer / device in order to serve it to
          you. My contact information is provided at the foot of this page if
          you have any questions.
        </p>
        <p>
          <strong>DPA & GDPR</strong>
          <br />
          This website complies with the DPA (Data Protection Act 1998) and
          already complies with the GDPR (General Data Protection Regulation)
          which comes into effect from May 2018. We will update this policy
          accordingly after the completion of the UK’s exit from the European
          Union.
        </p>
        <p>
          <strong>Use of Cookies</strong>
          <br />
          This website uses cookies to better the user’s experience while
          visiting the website. As required by legislation, where applicable
          this website uses a cookie control system, allowing the user to give
          explicit permission or to deny the use of /saving of cookies on their
          computer / device.
        </p>
        <p>
          <strong>What are cookies?</strong>
          <br />
          Cookies are small files saved to the user’s computers hard drive that
          track, save and store information about the user’s interactions and
          usage of the website. This allows the website, through its server to
          provide the users with a tailored experience within this website.
        </p>

        <p>
          Users are advised that if they wish to deny the use and saving of
          cookies from this website on to their computers hard drive they should
          take necessary steps within their web browsers security settings to
          block all cookies from this website and its external serving vendors
          or use the cookie control system if available upon their first visit.
          If you prefer to disable cookies on this site and on others. The most
          effective way to do this is to disable cookies in your browser. We
          suggest consulting the Help section of your browser or taking a look
          at the About Cookies website which offers guidance for all modern
          browsers.
        </p>
        <p>
          <strong>Website Visitor Tracking</strong>
          <br />
          This website uses tracking software (Google Anayltics) to monitor its
          visitors to better understand how they use it. The software will save
          a cookie to your computers hard drive in order to track and monitor
          your engagement and usage of the website, but will not store, save or
          collect personal information.
        </p>
        <p>
          <strong>Adverts & Sponsored Links</strong>
          <br />
          This website may contain sponsored links and adverts. These will
          typically be served through our advertising partners, to whom may have
          detailed privacy policies relating directly to the adverts they serve.
        </p>
        <p>
          Clicking on any such adverts will send you to the advertisers website
          through a referral program which may use cookies and will track the
          number of referrals sent from this website. This may include the use
          of cookies which may in turn be saved on your computers hard drive.
          Users should therefore note they click on sponsored external links at
          their own risk and we cannot be held liable for any damages or
          implications caused by visiting any external links mentioned.
        </p>
        <p>
          <strong>Downloads & Media Files</strong>
          <br />
          Any downloadable documents, files or media made available on this
          website are provided to users at their own risk. While all precautions
          have been undertaken to ensure only genuine downloads are available
          users are advised to verify their authenticity using third party
          anti-virus software or similar applications.
        </p>
        <p>
          We accept no responsibility for third party downloads and downloads
          provided by external third party websites and advise users to verify
          their authenticity using third party anti-virus software or similar
          applications.
        </p>

        <p>
          <strong>Contact & Communication</strong>
          <br />
          Users contacting this us through this website do so at their own
          discretion and provide any such personal details requested at their
          own risk. Your personal information is kept private and stored
          securely until a time it is no longer required or has no use.
        </p>

        <p>
          Where we have clearly stated and made you aware of the fact, and where
          you have given your express permission, we may use your details to
          send you products/services information through a mailing list system.
          This is done in accordance with the regulations named in ‘The policy’
          above.
        </p>

        <p>
          <strong>Email List & Marketing Messages</strong>
          <br />
          This website operates an email newsletter program using Mailerlite
          email marketing software, used to inform subscribers about products
          and services supplied by this website, and blogposts/news/articles
          that may be relevant. Users can subscribe through an online automated
          process (via Mailerlite automated sign up forms embedded onto this
          website) should they wish to do so but do so at their own discretion.
          Some subscriptions may be manually processed through prior written
          agreement with the user.All personal details relating to subscriptions
          are held securely and in accordance with the GDPR law. Under the GDPR
          law you may request a copy of personal information held about you by
          this website’s email newsletter program.
        </p>

        <p>
          Email marketing campaigns published by this website or its owner via
          Mailerlite may contain tracking facilities within the actual email.
          Subscriber activity is tracked and stored in a database stored on
          Mailerlite for future analysis and evaluation. Such tracked activity
          may include; the opening of emails, forwarding of emails, the clicking
          of links within the email content, times, dates and frequency of
          activity. This information is used to refine future email campaigns
          and supply the user with more relevant content based around their
          activity.Subscribers are given the opportunity to un-subscribe at any
          time through an automated system. This process is detailed at the
          footer of each email campaign.
        </p>

        <p>
          <strong>External Website Links & Third Parties</strong>
          <br />
          Although we only looks to include quality, safe and relevant external
          links, users are advised to adopt a policy of caution before clicking
          any external web links mentioned throughout this website.
        </p>

        <p>
          We cannot guarantee or verify the contents of any externally linked
          website despite our best efforts. Users should therefore note they
          click on external links at their own risk and we cannot be held liable
          for any damages or implications caused by visiting any external links
          mentioned.
        </p>

        <p>
          <strong>Social Media Policy & Usage</strong>
          <br />
          We adopt a Social Media Policy to ensure our business and our staff
          conduct themselves accordingly online. While we may have official
          profiles on social media platforms users are advised to verify
          authenticity of such profiles before engaging with, or sharing
          information with such profiles. We will never ask for user passwords
          or personal details on social media platforms. Users are advised to
          conduct themselves appropriately when engaging with us on social
          media.
        </p>

        <p>
          There may be instances where our website features social sharing
          buttons, which help share web content directly from web pages to the
          respective social media platforms. You use social sharing buttons at
          your own discretion and accept that doing so may publish content to
          your social media profile feed or page. You can find further
          information about some social media privacy and usage policies in the
          resources section below.
        </p>
        <p>
          If you have any questions regarding the above policy please contact me
          at hello@readymade-websites.co
        </p>

        <p>
          <strong>Ready Made Websites Support</strong>
          <br />
          Upon purchase of a Ready Made Website you will get access to my
          walkthrough course, as well as a growing video tutorial library, to
          help you get up and running.
        </p>

        <p>
          All support is handled through my private Facebook group – Ready Made
          Websites Support. Post your questions here or search the group content
          for answers. I hold a weekly ‘clinic’ live at 10am (BST) on a Friday
          for those that need help.
        </p>
        <p>
          Note: You will be entitled to receive all future updates of the theme
          for the lifetime of the product or until the product is is no longer
          available to purchase from sm web designs.
        </p>
        <p>
          I do not help with general WordPress related questions, theme
          customizations, coding modifications or any problems that might arise
          from them, or any problems you are having with software.
        </p>

        <p>
          The following is<strong> NOT</strong> part of my Ready Made Websites
          support:
        </p>
        <ul>
          <li>
            services to modify or extend the theme beyond the original features,
            style and functionality as described on the theme product page and
            theme demo.
          </li>
          <li>
            installation Wordpress or your chosen theme on to your hosting (This
            can be purchased as an add-on).
          </li>
          <li>
            problems with your web hosting or server environment, or issues with
            the WordPress installation you have installed on your own website or
            a client website.
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
